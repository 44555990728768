<template>
  <div class="constrain mt-48 mb-16">
    <div class="row-12">
      <div class="md:offset-3 md:col-9">
        <div class="back-link md:col-12 flex items-center mb-8">
          <ArrowLeft />
          <a class="ml-4 back-btn text-blue" :href="backUrl">{{ parentPageName }}</a>
        </div>
        <h1 class="leading-none" v-html="payload.titel" />
      </div>
      <div class="Galerie mt-16 mb-8 md:col-9 md:offset-3">
        <vue-picture-swipe :options="{mainClass: 'test', spacing: 0.1}" :items="images" />
      </div>
      <div class="pagination mt-4 md:offset-3 md:col-9 flex items-center">
        <div v-if="page > 0" class="pagination-arrow pagination-arrow-prev" @click="changePage(-1)">
          <PaginationRight />
        </div>
        <a
          v-for="pageNr in maxPages"
          :key="pageNr"
          class="pagination-link"
          :class="{active: pageNr === page + 1}"
          @click="pageNr > page ? changePage(pageNr - (page + 1)) : changePage(pageNr - page - 1)"
        >{{ pageNr }}</a>
        <div
          v-if="page < maxPages -1 "
          class="pagination-arrow pagination-arrow-next"
          @click="changePage(1)"
        >
          <PaginationRight />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VuePictureSwipe from 'vue3-picture-swipe';
import ArrowLeft from '../../assets/images/arrow-left.svg';
import PaginationRight from '../../assets/images/Pfeil.svg';

export default {
  components: {
    ArrowLeft,
    VuePictureSwipe,
    PaginationRight,
  },
  props: {
    payload: Object,
    perSlide: {
      type: Number,
      default: 16,
    },
    urlKey: {
      type: String,
      default: 'page',
    },
  },
  data: () => ({
    page: 0,
  }),
  computed: {
    backUrl() {
      return `/${this.$store.state.router.locale}/mitgliederbereich/${this.payload.parentSlug}`;
    },
    parentPageName() {
      return this.payload.parentTitle.toUpperCase();
    },
    images() {
      return this.payload.bilder.slice(this.page * this.perSlide, this.page * this.perSlide + this.perSlide);
    },
    maxPages() {
      return Math.ceil(this.payload.bilder.length / this.perSlide);
    },
  },
  mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has(this.urlKey)) this.page = urlParams.get(this.urlKey) * 1;
  },
  methods: {
    changePage(direction) {
      this.page += direction;
      window.history.replaceState(window.history.state, null, `?${this.urlKey}=${this.page}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.Galerie {
  :deep(.my-gallery) {
    display: inline-flex;
    flex-wrap: wrap;
    gap: px(25) px(0);
    @screen sm {
      gap: px(30) px(30);
    }
    @screen md {
      gap: px(25) px(25);
    }
    @screen lg {
      gap: px(50) px(50);
    }
    figure {
      margin: 0px;
      display: flex;
      flex-direction: column;
      flex-basis: 100%;
      height: px(250);
      width: 100%;
      @screen sm {
        height: px(300);
        flex-basis: calc(50% - px(15));
      }
      @screen md {
        flex-basis: calc(50% - px(12.5));
      }
      @screen lg {
        flex-basis: calc(33% - px(30));
      }
      @screen xl {
        flex-basis: calc(25% - px(40));
      }
      a {
        height: 100%;
        width: 100%;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
}

.pagination {
  justify-content: center;
  @screen md {
    justify-content: flex-end;
  }
  &-arrow {
    display: flex;
    align-items: center;
    svg {
      color: var(--color-blue);
      width: px(15);
      height: px(15);
      transition: color .25s ease-out;
      @screen md {
        width: px(20);
        height: px(20);
      }
    }
    &-prev {
      transform: rotate(180deg);
    }
  }
  &-link {
    color: var(--color-blue);
    font-weight: 600;
    font-size: px(14);
    @screen md {
      font-size: px(18);
    }
    &.active {
      background-color: var(--color-blue);
      color: var(--color-white);
    }
  }
  &-arrow, &-link {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    height: px(30);
    width: px(30);
    border: 1px solid var(--color-blue);
    border-radius: 50%;
    transition: background-color .25s ease-out, color .25s ease-out;
    @screen md {
      height: px(45);
      width: px(45);
      border: 2px solid var(--color-blue);
    }
    &:not(:last-child) {
      margin-right: .5rem;
      @screen md {
        margin-right: 1rem;
      }
    }
    &:hover {
      background-color: var(--color-blue);
      color: var(--color-white);
      svg {
        color: white;
      }
    }
  }
}

</style>
